import React, { useState } from 'react';
import BoldTitle from './BoldTitle';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Image } from './image';

const CollaborationPartners = ({ collaborationPartners }) => {
  const [showAll, setShowAll] = useState(false);

  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  const partnersToShow = showAll
    ? collaborationPartners
    : collaborationPartners.slice(0, 8);

  return (
    <div className="w-full max-w-6xl px-6 py-16 m-auto space-y-16 md:py-24">
      <div className="space-y-4">
        <BoldTitle className="leading-snug md:text-center">
          Our <span className="text-primary">Partners</span>
        </BoldTitle>
        <div className="w-full max-w-2xl m-auto text-sm text-center md:text-base">
          <p className="leading-7">
            Since our journey started in 2015, many organisations and
            individuals have supported our projects in various ways. We
            appreciate their contributions. May Allah reward them all. We&apos;d
            like to acknowledge some of them here.
          </p>
        </div>
      </div>
      <div className="grid w-full grid-cols-1 gap-6 mt-8 md:gap-8 sm:grid-cols-3 lg:grid-cols-4">
        {partnersToShow.map((partner) => {
          const { id, title, description, imageFile, link } = partner;
          return (
            <div
              className="flex flex-col p-4 space-y-2 shadow-xl rounded-2xl"
              key={id}
            >
              <Image alt={`Image of ${title}`} imageFile={imageFile} />
              <div className="flex-1 min-h-[95px]">
                <p className="text-sm font-bold text-accent">{title}</p>
                <p className="text-sm leading-6 text-accent-3">{description}</p>
              </div>
              <div className="flex justify-end">
                <a href={link} target="_blank" rel="nofollow noreferrer">
                  <FontAwesomeIcon
                    className="text-2xl text-primary hover:text-primary-4"
                    icon={faArrowRight}
                  />
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-center">
        <button
          className="px-8 py-4 font-semibold transition-all duration-300 border rounded-lg border-primary text-primary hover:bg-primary-0 hover:shadow-xl"
          onClick={handleToggleShow}
        >
          {showAll ? 'See Less' : 'See More'}
        </button>
      </div>
    </div>
  );
};

CollaborationPartners.propTypes = {
  collaborationPartners: PropTypes.array.isRequired,
};

export default CollaborationPartners;
